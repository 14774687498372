#notfound {
  position: relative;
  height: 100vh;
}

#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.notfound {
  text-align: center;
}

.notfound .notfound-404 {
  height: 230px;
  position: relative;
  z-index: 1;
}

.notfound-404 h1 {
  font-family: "Montserrat", sans-serif;
  font-size: 150px;
  font-weight: 900;
  color: #e3001b;
  margin-top: 10%;
  left: 50%;
}

.notfound h2 {
  font-family: "Montserrat", sans-serif;
  color: #000;
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 0;
}

.notfound p {
  font-family: "Montserrat", sans-serif;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 20px;
  margin-top: 0px;
}

.notfound a {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  text-decoration: none;
  text-transform: uppercase;
  background: #e3001bab;
  display: inline-block;
  padding: 15px 30px;
  border-radius: 40px;
  color: #fff !important;
  font-weight: 700;
  box-shadow: 0px 4px 15px -5px #000000;
}

@media only screen and (max-width: 767px) {
  .notfound .notfound-404 {
    height: 142px;
  }
  .notfound .notfound-404 h1 {
    font-size: 112px;
  }
}

.already-logged-in {
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center; /* horizontal, when using flex-direction: row; */
  align-items: center;
}
