.issue_details_header{
    display:flex;
}
.issue_details_header h2 {
    font-size: 1.4em;
    font-weight: 600;
    color: #5d5d5d !important;
    font-family: "Montserrat", sans-serif !important;
    width: 90%;
}
.issue_main_container{
    display:flex;
 

}
.issue_details_header p{
    font-size: 0.5em;
    font-weight: bold;
    color: #000;
}
.issue_status {
    font-size: 0.4em;
    font-weight: 500;  
    background-color: #cbffd7;
    color: #15852f;
    width: 12vh;
    text-align: center;
    border-radius: 2rem;
    padding: 0.4rem;
}
/* NavbarTop */
.navbar-top {
    background-color: #fff;
    color: #333;
    box-shadow: 0px 4px 8px 0px grey;
    height: 70px;
}

.title {
    font-family:  "Montserrat", sans-serif !important;
    padding-top: 15px;
    position: absolute;
    left: 45%;
}


.main {    
    font-size: 28px;
    padding: 0 10px;
}

.main h2 {
    color: #333;
    font-family: "Montserrat", sans-serif !important;
    font-size: 24px;
    width: 70%;
 
    margin-bottom: 10px;
}
.card{
    border: none;    
}
.issue_details{
    border-top: 1px solid #e0e0e0;
    padding-top:0.8rem ;
    display: flex;

}
.issue_details h5{  
    font-size: 1.1rem;
}
.issue_details h4 {
    font-size: 0.9rem;
    font-weight: 400;
    color: #5d5d5d;
    padding: 0.2rem 0.5rem;
}
.main .card table {
    border-top: 1px solid #e5e5e5;
    font-size: 16px;
    height: 270px;

    width: 100%;

}

.issue_lt_container{
    width: 80%;
    float: left;
}

.issue_rt_container{
    width: 20%;
    float: right;
    padding: 0 1rem 1rem 1rem;
    border: 1px solid #e5e5e5;
    height: 100%;
}

.issue_lt_container .issue_details p{
    font-size: 0.8rem;
    font-weight: 400;
    color: #5d5d5d;
    padding: 0.2rem 0.5rem;
}
.issue_response_details{
    display: flex;   
    padding-top:0.8rem ;

}
.issue_response_details h4{  
    margin: 0.4rem 0;
    float: left;
    font-weight: 500;
    font-size: 0.8rem;

}
.issue_response_details h5 {
   margin: auto 0rem; 
    font-weight: 500;
    font-size: 0.8rem;
    color: #5d5d5d;
    padding: 0rem 0rem 0rem 0.5rem;
}
.issue_response_details span{
    font-size: 0.7em;
    font-weight: 400;
    background-color: #ffcbcb;
    color: #bc0016;
    width: auto;
    margin: auto 1rem;
    text-align: center;
    border-radius: 2rem;
    padding: 0.2rem 0.4rem;
}
.issue_type {
    display: flex;
}
.issue_type h5{
    font-size: 0.4em;
    font-weight: 400;  
    background-color: #b9fcaea6;
    color: #005c15;
    width: auto;
    text-align: center;
    margin: auto 1rem;
    border-radius: 2rem;
    padding: 0.2rem 0.4rem;
}

.issue_comment p{
    font-size: 0.9rem;
    font-weight: 400;
    color: #5d5d5d;   
    margin-bottom: 0.5rem;

}
.issue_comment span{
    border-radius: 1rem;
    color: #32325d;
    background-color: #dceeff;
    padding: 0rem 0.5rem;
    font-weight: 500;
}


  .issue_activity_header h4{
    font-weight: 500;
    font-size: 1.2rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .tab-list {
    height: 50px;
    font-family: "Montserrat", sans-serif !important;
    display: flex;
    list-style: none;
    font-size: 0.9rem;
    padding: 0;
    margin: 0;
  }
  .tab-list p{
    margin-right: 1rem;
    font-weight: 500;
  }
  
  .tabs {
    width: 7rem;
    height: 50%;
    margin: 0 0.1rem;
    display: flex;
    justify-content: center;
    color: #04356b;
    align-items: center;
    background: #deeeff;
    font-weight: 500;
    position: relative;
    cursor: pointer;
    border-radius: 0.5rem;
  }  

  .active-tabs {
    background: #426386;
    color: #fff;
    font-weight: 500;
  }  
  
  .content {
      display: none;
      padding: 10px;
  }
  
  .active-content {
      display: block;
  }
  .issue_history{
    white-space: pre-wrap;
    word-wrap: break-word;
  }
  .issue_history p{
    display: inline-block;
    font-weight: 500;
    color: #5d5d5d !important;
    font-family: "Montserrat", sans-serif !important;
    font-size: 0.9rem;
  }
  .issue_history th{
        width: 33%;
  }
  .issue_history h3{
    color: #515151 !important;
    font-family: "Montserrat", sans-serif !important;
    font-size: 1rem;
    margin-top: 1rem;
    margin-bottom: 0;
    font-weight: 600;
  }
  
@media ( max-width:1440px ) {
    .issue_lt_container{
        width: 70% !important;
        float: left;
    }
    
    .issue_rt_container{
        width: 30% !important;
        float: right;    
    }
    .issue_response_details{
        display: flex;   
    }
}

@media ( max-width: 779px){
    .issue_lt_container{
        width: 100% !important;     
    }
    
    .issue_rt_container{
        width: 100% !important;      
    }
    .issue_main_container{
        display:flex;
        flex-direction: column-reverse;
    }
    .issue_details h5{  
        font-size: 0.9rem;
    }
    .issue_details h4 {
        font-size: 0.8rem;
    }
    .issue_comment p {
        font-size: 0.8rem;
    }
    .main h2 {
        font-size: 1.2rem;
    }
    
}
