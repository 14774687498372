

@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700";

body{
  font-family: "Montserrat", sans-serif !important;
}
.navbar p {
  font-family: "Montserrat", sans-serif !important;
  font-size: 1.1em;
  font-weight: 300;
  line-height: 1.7em;
  color: #999;
}

.navbar {
  padding: 15px 10px;
  background: #fff;
  border: none;
  border-radius: 0;
  margin-bottom: 40px;
}

.navbar-btn {
  box-shadow: none;
  outline: none !important;
  border: none;
}

.line {
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #ddd;
  margin: 40px 0;
}


.wrapper {
  display: flex;
  align-items: stretch;
}

#sidebar {
  min-width: 250px;
  max-width: 250px;
  background: rgb(255, 217, 222);
  margin-right: 1vh;
  float: left;
  margin-left:-250px;
  height: 100%;
  position: fixed;
  z-index: 100;
  color: rgb(227, 0, 27);
  transition: all 0.3s;

}

.fix-sidebar{
  padding-left: 16.5rem !important;  
  transition: all 0.3s;
  padding-right: 1.5rem !important;
}


#sidebar a,
#sidebar a:hover,
#sidebar a:focus {
  color: inherit;
}

#sidebar.active {
  margin-left: 0px;

}

#sidebar .sidebar-header {
  padding: 20px;
  background: rgb(255, 217, 222);
  margin-top:1.5rem;
}

#sidebar ul.components {
  padding: 20px 0;
  
}

#sidebar ul p {
  color: #fff;
  padding: 10px;
}

#sidebar ul li a {
  padding: 10px;
  font-size: 1.1em;
  font-family: "Montserrat", sans-serif !important;
  display: block;
}

#sidebar i{
  display: none;
}

#sidebar ul li.active > a,
a[aria-expanded="true"] {
  color: #fff !important;
  background: #ff6868;
}

a[data-toggle="collapse"] {
  position: relative;
}

.sidebar-close-btn{
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5rem;
  font-size: 1.5rem;
  color: #fff;
  border: none;
  display: none;
  cursor: pointer;
}


.toggle-btn{
  cursor: pointer;
  margin-right: 1rem;

}
.toggle-btn-close{
  margin-right: 1rem;
}

#content {
   transition: all 0.3s;
}

#content p a {
  color: #ffffff00;
}

@media (max-width: 768px) {
  #sidebar {
    margin-left: -250px;
  }
  #sidebar.active {
    margin-left: 0px;
  }
  #sidebarCollapse span {
    display: block;    
  }
  .toggle-btn-close{
    display: none;
  }
  .sidebar-close-btn{
    display: block;
  }
  .fix-sidebar{
    padding-left: 0rem !important;  
    transition: all 0.3s;
    padding-right: 0rem !important;
  }  
  #sidebar i{
    display: block;
  }
  
  
}
@media (max-width: 440px) {
  .navbar {
    padding-top: 1rem;
    margin-bottom: 2rem;
 
}

#sidebar i{
  display: block;
}

.navbar-dropdown-btn{
  position: absolute !important;
  top: 70% !important;
  right: -4% !important;
  width: 4.5rem !important;
  height: 2.3rem !important; 
}
}
