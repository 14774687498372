.content {
  padding: 7rem 0;
}

.login_form_title {
  font-weight: 700 !important;
  font-size: 24px !important;
  font-style: normal !important;
  line-height: 36px;
  color: #000000 !important;
}

.login_form_description {
  font-weight: 400 !important;
  font-size: 24px !important;
  line-height: 36px;
  color: #7c7c7c !important;
}

h2 {
  font-size: 20px;
}

.half,
.half .container > .row {
  font-family: "Montserrat", sans-serif !important;
  height: 100vh;
  min-height: 700px;
}

@media (max-width: 991.98px) {
  .half .bg {
    height: 200px;
  }
}

@media (max-width: 500.98px) {
  .half .bg {
    display: none;
  }
}

.half .contents {
}

.half .contents,
.half .bg {
  width: 50%;
}
@media (max-width: 1199.98px) {
  .half .contents,
  .half .bg {
    width: 100%;
  }
}
.half .contents .form-control,
.half .bg .form-control {
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  height: 54px;
}
.half .contents .form-control:active,
.half .contents .form-control:focus,
.half .bg .form-control:active,
.half .bg .form-control:focus {
  outline: none;
  border-color: #7c7c7c !important;
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
}

.half .bg {
  background-size: cover;
  background-position: center;
}

.half a {
  color: #888;
  text-decoration: underline;
}

.half .btn {
  background-color: #e3001b;
  color: #fff;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 700;
  font-size: 1rem;
  line-height: 24px;
  height: 54px;
  padding-left: 30px;
  padding-right: 30px;
}

.half .forgot-pass {
  text-decoration: none;
  color: #e3001b;
  position: relative;
  top: 2px;
  font-family: "Inter", sans-serif !important;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
}

.control {
  display: block;
  position: relative;
  margin-bottom: 15px;
  cursor: pointer;
  font-size: 14px;
}
.control .caption {
  position: relative;
  left: 1.7rem;
  float: left !important;
  bottom: 2px;
  color: #7C7C7C;
  font-family: "Inter", sans-serif !important;
}
.caption_forgot_password {
  position: relative;
    /* left: 12rem; */
    float: right !important;
    bottom: 2.5rem;
    color: #7C7C7C;
    font-family: "Inter", sans-serif !important;
}

.control input {
  position: absolute;
  opacity: 1;
}

input[name="remember"],
input[name="remember"]:checked,
input[name="remember"]:hover,
input[name="remember"]:focus {
  width: 1rem;
  height: 1rem;
  accent-color: #e3001b;
}

.login_banner {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 500;
  font-size: 1rem;
  line-height: 19.5px;
  position: absolute;
  top: 64px;
  color: #4e4d4e;
}

.image_container_content {
  position: absolute;
  bottom: 3rem;
  left: 3rem;
}

.image_container_content_title {
  font-family: "Inter", sans-serif !important;
  font-weight: 900;
  font-style: italic;
  font-size: 2rem;
  line-height: 38.73px;
  color: #ffffff;
  margin-left: 1rem;
}

.password_icon{
  right: 0.8rem;
  top: 30%
}