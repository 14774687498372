/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}




.big_text {
  display: flex;
  width: 100%;
  /* place-items: center; */
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 8rem 0;
  text-align: center;
}
.big_text h1 {
  font-size: 10rem;
  transform:translatez(20rem);
  
}
.color_blue{
  color:#375582;
  text-shadow: none;
  animation: visible 2s infinite;

}
.big_text p{
  letter-spacing:1px;
}
.small_texts {
  display: flex;
  justify-content: space-around;
  
}
.small_texts p {
  color: #888a85;
  animation: float 8s infinite;
  transform: translatey(4rem);
}

html {
  font-family: 'Montserrat', sans-serif !important;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}